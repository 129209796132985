// Footer.js
import React from 'react';
import InsiteDigitalLogo from '../assets/InsiteDigitalLogo_White.png'

const Footer = React.forwardRef((props, ref) => {
  return (
    <div ref={ref}>
      <footer style={{
        position: 'fixed',
        left: 0,
        bottom: 0,
        width: '100%',
        marginTop: '2vh',
        color: 'white',
        textAlign: 'center',
        zIndex: 1, // Add this line
      }}>
        <div>
        <img
          src={InsiteDigitalLogo}
          alt="IDLogo"
          style={{ height: 30, marginTop: 10 }}
        />
        </div>
        <div style={{ position: 'absolute', left: '10px', bottom: '10px', fontSize: '0.7rem' }}>
          Version 1.1.6
        </div>
      </footer>
    </div>
  );
});

export default Footer;