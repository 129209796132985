import React, { useEffect } from 'react';
import { UserProvider } from './providers/UserContext';
import { AppProvider } from './providers/AppContext';
import AppRoutes from './routes/routes';
import { AuthenticatedTemplate, useMsal, UnauthenticatedTemplate } from '@azure/msal-react';
import { loginRequest } from './auth/authConfig';
import backgroundImage from './assets/SchickBackGround2.png'
import backgroundmobile from './assets/wallpapermobile.png'
import civilXLogo from "./assets/Civilx Logo Main White.svg";
import insiteDigitalLogo from './assets/InsiteDigitalLogo_White.png'
import { Box, Button, Typography, useMediaQuery } from '@mui/material';
import './App.css';

function App() {
  const { instance } = useMsal();
  const activeAccount = instance.getActiveAccount();
  const matches = useMediaQuery("(min-width:600px)");

const handleRedirect = () => {
  if (!instance.getActiveAccount()) {
    instance
      .loginRedirect({
        ...loginRequest,
        prompt: 'create',
      })
      .catch((error) => console.log(error));
  }
};

useEffect(() => {
  if (!activeAccount) {
    handleRedirect();
  }
  // eslint-disable-next-line 
}, [activeAccount]);

  return (
    <>
      <UserProvider>
        <AuthenticatedTemplate>
          {activeAccount ? (
            <AppProvider>
            <AppRoutes />
            </AppProvider>
          ) : null}
        </AuthenticatedTemplate>
        <UnauthenticatedTemplate>
        <Box
            sx={{
              position: 'relative',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              minHeight: '100%',
              height: '93vh',
              p: 4,
              overflow: 'hidden',
              color: 'white',
              backgroundImage: `url(${backgroundImage})`,
              backgroundPosition: 'fit',
              backgroundSize: 'cover',
            }}
          >
            <Box sx={{ position: 'absolute', top: 0, bottom: 0, left: 0, right: 0, bgcolor: 'black', opacity: 0.3 }} />
            <img src={civilXLogo} style={{ width: '20vw', zIndex: 11}} alt="CivilX" />
            <Box sx={{ zIndex: 10, p: 3, color: 'white', bgcolor: 'white.alpha.0', borderRadius: 'default' }}>
              <Typography variant="h2" sx={{ mb: 2, fontWeight: 'bold', textAlign: 'center', fontSize: '7xl' }}>
                Seamless Digital Experience.
              </Typography>
              {/* <Typography variant="body1" sx={{ mx: 4, mb: 2, textAlign: 'center', fontSize: '2xl' }}>
                Effortlessly manage site tasks, track hours, and ensure projects stay on course.
              </Typography> */}
              </Box>
              <Button
                sx={{
                  px: 6,
                  py: 2,
                  mb: 8,
                  fontWeight: 'bold',
                  color: '#ffffff',
                  transition: 'all 300ms',
                  borderRadius: 'full',
                  boxShadow: 3,
                  bgcolor: 'slate.200',
                  '&:hover': {
                    bgcolor: 'slate.300',
                  },
                  '&:focus': {
                    outline: 'none',
                    borderColor: 'slate.400',
                    boxShadow: '0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04)',
                  },
                }}
                onClick={handleRedirect}
              >
                Launch myReview
              </Button>
            <img src={insiteDigitalLogo} alt="InsiteDigital" style={{ position: 'absolute', bottom: 0, width: '3vw', zIndex: 11}} />
          </Box>
        </UnauthenticatedTemplate>
      </UserProvider>
    </>
  );
}


export default App;
