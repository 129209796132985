// This hook is used to get the user's geolocation.
import { useState, useEffect } from 'react';
import { useGeolocated } from 'react-geolocated';

function useMyGeolocation() {
    const geolocation = useGeolocated();
    const { isGeolocationAvailable, isGeolocationEnabled, coords } = geolocation;
    const [latLon, setLatLon] = useState({ lat: null, lon: null });

    useEffect(() => {
        if (!isGeolocationAvailable) {
            // Geolocation is not supported by this browser
            console.error("Geolocation is not supported by this browser.");
        } else if (!isGeolocationEnabled) {
            // Geolocation is not enabled
            console.error("Geolocation is not enabled.");
        } else if (coords) {
            setLatLon({
                lat: coords.latitude,
                lon: coords.longitude
            });
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [coords]); // Only re-run the effect if coords changes

    return latLon;
}

export default useMyGeolocation;