import React, { createContext, useContext, useState } from 'react';

const AppContext = createContext();

export const AppProvider = ({ children }) => {
  // State for each category total
  const [currentPage, setCurrentPage] = useState(1);
  const [varDate, setvarDate] = useState(new Date());
  const [isManager, setIsManager] = useState(false);
  const [ManagerMenu, setManagerMenu] = useState(false);
  const [ManagerView, setManagerView] = useState(false);
  const [currentStep, setCurrentStep] = useState(0);
  const [enteredByUser, setenteredByUser] = useState("");
  const [CurrentLocation, setCurrentLocation] = useState("");
  const [formData, setFormData] = useState([]);
  const [CompanyID, setCompanyID] = useState(0);
  const [TemplateMenu, setTemplateMenu] = useState([]);

  
  // Update the Page Number
  const updateCurrentPage = (newPage) => setCurrentPage(newPage);
  const updatevarDate = (newDate) => setvarDate(newDate);
  const updateCurrentStep = (newStep) => setCurrentStep(newStep);
  const updateenteredByUser = (newUser) => setenteredByUser(newUser);
  //Location
  const updateCurrentLocation = (newCurrentLocation) => setCurrentLocation(newCurrentLocation);
  //DataStore for Form
  const updateFormData = (newFormData) => setFormData(newFormData);
  // Check if the user is a manager
  const updateIsManager = (newIsManager) => setIsManager(newIsManager);
  const updateManagerMenu = (newManagerMenu) => setManagerMenu(newManagerMenu);
  const updateManagerView = (newManagerView) => setManagerView(newManagerView);

  const updateCompanyID = (newCompanyID) => setCompanyID(newCompanyID);

  const updateTemplateMenu = (newTemplateMenu) => setTemplateMenu(newTemplateMenu);

  return (
    <AppContext.Provider value={{
      currentPage,
      updateCurrentPage,
      varDate,
      updatevarDate,
      currentStep,
      updateCurrentStep,
      enteredByUser,
      updateenteredByUser,
      CurrentLocation,
      updateCurrentLocation,
      formData,
      updateFormData,
      isManager,
      updateIsManager,
      ManagerMenu,
      updateManagerMenu,
      ManagerView,
      updateManagerView,
      CompanyID,
      updateCompanyID,
      TemplateMenu,
      updateTemplateMenu
    }}>
      {children}
    </AppContext.Provider>
  );
};

export const useAppContext = () => useContext(AppContext);
