import React, { useState, useEffect, useRef } from "react";
import {
  TextField,
  Select,
  useMediaQuery,
  Box,
  Grid,
  InputAdornment,
  Avatar,
  FormControl,
  InputLabel,
  MenuItem,
} from "@mui/material";

import { getUserPicture } from "../../hooks/usefetchOtherUserInfo";
import CreateComments from "../../components/createComments";
import { useUser } from "../../providers/UserContext";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { useNavigate } from "react-router-dom";
import { useAppContext } from "../../providers/AppContext";
import {
  getManagerAllComments,
  postNewComment,
  getReviewTemplates,
} from "../../hooks/usefetchCivilxApi";
import { Loading } from "../../components/loading";

function ManagerInterface({ directReports }) {
  const [PicturedirectReports, setPictureDirectReports] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [selectedTemplates, setSelectedTemplates] = useState({});
  const [commentsData, setCommentsData] = useState([]);
  const { sid } = useUser();
  const [managerComments, setManagerComments] = useState([]);
  const matches = useMediaQuery("(min-width:600px)");
  const [isCreatingComment, setIsCreatingComment] = useState(false);
  const [commentUserSid, setCommentUserSid] = useState(null);
  const [commentTemplate, setCommentTemplate] = useState(null);
  const navigate = useNavigate();
  const {
    currentPage,
    updateCurrentPage,
    CompanyID,
    TemplateMenu,
    updateManagerMenu,
    ManagerMenu,
  } = useAppContext();
  const elementRef = useRef(null);
  const [isLoading, setIsLoading] = React.useState(true);
  const [method, setMethod] = useState("PATCH");

  useEffect(() => {
    // `sid` is the manager's sid
    getManagerAllComments(sid).then((ManagerComments) => {
      if (!ManagerComments || ManagerComments.length === 0) {
        // If no comments are found, set method to 'POST'
        setMethod("POST");
      } else {
        setManagerComments(ManagerComments);
        setMethod("PATCH");
      }
    });
  }, [sid, isCreatingComment]);

  useEffect(() => {
    Promise.all(
      directReports.map(async (user) => {
        const picture = await getUserPicture(user.id);
        let questionTemplate = "";
        // Check if managerComments and its nested properties exist
        if (
          managerComments &&
          managerComments[0] &&
          managerComments[0].record &&
          managerComments[0].record.Comments
        ) {
          const userComment = managerComments[0].record.Comments.find(
            (comment) => comment.id === user.id
          );
          if (userComment) {
            questionTemplate = userComment.QuestionTemplate;
          }
        }

        return { ...user, picture, questionTemplate };
      })
    ).then((usersWithPictures) => {
      setPictureDirectReports(usersWithPictures);
    });
    setTimeout(() => {
      setIsLoading(false);
    }, 2000);
    // eslint-disable-next-line
  }, [directReports, managerComments]);

  useEffect(() => {
    if (elementRef.current) {
      elementRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  }, [currentPage]);

  const handleTemplateSelect = (event, userSid) => {
    const selectedTemplate = event.target.value;

    const userConfirmed = window.confirm(
      "Changing the template will erase all current comments. Are you sure you want to proceed?"
    );

    if (userConfirmed) {
      setCommentTemplate(selectedTemplate);
      getReviewTemplates().then((response) => {
        const templateRecord = response.find((item) =>
          item.record.Templates.some(
            (template) => template.Type === selectedTemplate
          )
        );
        const template = templateRecord
          ? templateRecord.record.Templates.find(
              (template) => template.Type === selectedTemplate
            )
          : null;

        if (template) {
          const initialComments = template.Sections.flatMap((section) =>
            section.SectionQuestions.map((question) => ({
              Question: question.Question,
              CommentValue: "",
              MyNotes: "",
            }))
          );

          const comments = {
            CompanyID: CompanyID,
            record: {
              Manager: sid,
              Comments: [
                {
                  id: userSid,
                  QuestionTemplate: selectedTemplate,
                  Questions: initialComments,
                },
              ],
            },
            type: "Manager",
          };
          setCommentsData([
            ...commentsData.filter((c) => c.id !== userSid),
            comments.record.Comments[0],
          ]);
          // Create a new object that only includes the properties you're interested in
          const commentsSubset = {
            manager: comments.record.Manager,
            id: comments.record.Comments[0].id,
            QuestionTemplate: comments.record.Comments[0].QuestionTemplate,
            Questions: comments.record.Comments[0].Questions,
          };

          // Store the new object in localStorage
          localStorage.setItem(userSid, JSON.stringify(commentsSubset));

          setPictureDirectReports((prev) =>
            prev.map((user) =>
              user.id === userSid
                ? { ...user, questionTemplate: selectedTemplate }
                : user
            )
          );

          postNewComment(sid, userSid, comments, method)
            .then((data) => {
              if (method === "POST") {
                setMethod("PATCH");
              }
            })
            .catch((error) => console.error("Error posting comment:", error));
        }
      });
    }
  };

  const handleCommentCreation = () => {
    // This function will be called when the data is submitted
    setIsCreatingComment(false);
  };

  const handleRowDoubleClick = (user) => {
  // Set the user sid and template to state
  updateCurrentPage(1);
  setIsCreatingComment(true);
  setCommentUserSid(user.id);
  setSelectedUser(`${user.displayName} - ${user.jobTitle}`);
  setCommentTemplate(user.questionTemplate);
  console.log("Staff: ", user.questionTemplate);

  // Find the comment for the selected user in managerComments
  const managerComment = managerComments.find((comment) =>
    comment.record.Comments.some((c) => c.id === user.id)
  );

  if (managerComment) {
    // Find the specific comment for the user
    const userComment = managerComment.record.Comments.find(
      (c) => c.id === user.id
    );

    // Check if all CommentValue and MyNotes in Questions are empty
    const areAllFieldsEmpty = userComment.Questions.every(
      (q) => q.CommentValue === "" && q.MyNotes === ""
    );

    // If not all fields are empty, use the database copy
    if (!areAllFieldsEmpty) {
      // Create a new object that only includes the properties you're interested in
      const commentsSubset = {
        manager: managerComment.record.Manager,
        id: userComment.id,
        QuestionTemplate: userComment.QuestionTemplate,
        Questions: userComment.Questions,
      };

      // Store the new object in localStorage
      localStorage.setItem(user.id, JSON.stringify(commentsSubset));
    } else {
      // If all fields are empty, check local storage
      const storedComments = localStorage.getItem(user.id);

      // If there are no stored comments, use the database copy
      if (!storedComments) {
        const commentsSubset = {
          manager: managerComment.record.Manager,
          id: userComment.id,
          QuestionTemplate: userComment.QuestionTemplate,
          Questions: userComment.Questions,
        };

        // Store the new object in localStorage
        localStorage.setItem(user.id, JSON.stringify(commentsSubset));
      }
    }
  }
};

  const myTextFieldStyle = {
    ".MuiOutlinedInput-root": {
      borderRadius: "25px",
      fontSize: "12px",
      height: [40, 40, 40],
      width: "95%", // Adjust width as needed
    },
    ".MuiInputLabel-root": {
      fontSize: "12px", // Adjust font size,
    },
    ".MuiInputAdornment-root": {
      fontSize: "12px", // Adjust font size
    },
    "& .MuiInputLabel-outlined.MuiInputLabel-shrink": {
      // Adjust the position of the label when shrunk (e.g., when the input has content or focus)
      transform: "translate(14px, -6px) scale(0.75) !important",
    },
  };

  const handleIconClick = () => {
    if (!isCreatingComment) {
      updateManagerMenu(!ManagerMenu);
    } else {
      let previousPage = currentPage - 1;
      console.log("Current Page:", currentPage);
      if (previousPage >= 1) {
        updateCurrentPage(previousPage);
        console.log("previous Page: ", previousPage);
      } else if (previousPage === 0) {
        setIsCreatingComment(false);
        updateCurrentPage(1);
      } else {
        updateCurrentPage(1);
        navigate("/");
      }
    }
  };

  return (
    <Box
      sx={{
        backgroundColor: "rgba(255, 255, 255, 0.85)",
        overflowY: "auto",
        width: matches ? "60%" : "80%",
        height: matches ? "80vh" : "75vh",
        marginLeft: matches ? "20%" : "2%", // Center the container
        boxShadow:
          "0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04)",
        borderRadius: "1.5rem",
        position: "absolute", // Change this to absolute
        top: matches ? "7%" : "10%", // Adjust this value to position the container relative to the top of the screen
        zIndex: 2, // Add this line
        display: "flex", // Add this line
        justifyContent: "flex-start", // Change this line
        flexDirection: "column", // Change this line
        alignItems: "flex-start",
        padding: "2rem", // Add this line
        color: "black", // Add this line
        "&::-webkit-scrollbar": {
          width: "0.2em",
        },
        "&::-webkit-scrollbar-track": {
          WebkitBoxShadow: "inset 0 0 3px rgba(0,0,0,0.00)",
        },
        "&::-webkit-scrollbar-thumb": {
          backgroundColor: "rgba(0,0,0,.1)",
        },
        "&:hover::-webkit-scrollbar-thumb": {
          backgroundColor: "rgba(0,0,0,.4)",
        },
      }}
    >
      <div style={{ minWidth: "100%" }} ref={elementRef}>
        {isLoading && <Loading message="Retrieving Users... Please wait" />}

        <ArrowBackIosNewIcon
          sx={{
            position: "absolute",
            top: 10,
            left: 5,
            fontSize: "1.25rem",
            color: "black",
          }}
          onClick={handleIconClick}
        />
        {isCreatingComment ? (
          <>
            <h3>{selectedUser}</h3>
            <CreateComments
              userSid={commentUserSid}
              QuestionTemplate={commentTemplate}
              onCommentCreated={handleCommentCreation}
              method={method}
            />
          </>
        ) : (
          <>
            <h3>Direct Reports</h3>
            <Grid container spacing={3}>
              {PicturedirectReports.map((user, index) => (
                <Grid item xs={12} container alignItems="center" key={user.id}>
                  <Grid item xs={12} sm={4}>
                    <TextField
                      fullWidth
                      sx={myTextFieldStyle}
                      label="Display Name"
                      value={user.displayName}
                      onClick={() => handleRowDoubleClick(user)}
                      InputProps={{
                        readOnly: true,
                        startAdornment: (
                          <InputAdornment position="start">
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                height: matches ? "2vh" : "1vh",
                                width: "2vw",
                                borderRadius: "50%",
                                marginLeft: matches ? "-0.75vw" : "0.5vw",
                                backgroundColor: "transparent",
                              }}
                            >
                              <Avatar
                                src={user.picture}
                                sx={{ width: 26, height: 26}}
                              />
                            </div>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                  {matches && (
                    <>
                      <Grid item xs={12} sm={4}>
                        <TextField
                          fullWidth
                          sx={myTextFieldStyle}
                          label="Job Title"
                          value={user.jobTitle}
                          InputProps={{
                            readOnly: true,
                            startAdornment: (
                              <InputAdornment position="start">
                                {" "}
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <FormControl
                          fullWidth
                          variant="outlined"
                          sx={{
                            "& .MuiOutlinedInput-root": {
                              borderRadius: "50px", // Apply borderRadius to the OutlinedInput
                              margin: "0px 5px 0px 0px", // Add margin to create whitespace
                            },
                            "& .MuiInputLabel-outlined": {
                              transform: "translate(14px, 16px) scale(1)", // Adjust label initial position if necessary
                            },
                            "& .MuiInputLabel-outlined.MuiInputLabel-shrink": {
                              transform: "translate(12px, -6px) scale(0.55)", // Adjust label position when shrunk (if necessary)
                            },
                          }}
                        >
                          <InputLabel htmlFor="ReviewTemplate">
                            Review Template
                          </InputLabel>
                          <Select
                            value={user.questionTemplate || " "}
                            onChange={(e) => {
                              setSelectedTemplates({
                                ...selectedTemplates,
                                [user.id]: e.target.value,
                              });
                              handleTemplateSelect(e, user.id);
                            }}
                            sx={{
                              ...myTextFieldStyle,
                              height: 40,
                              fontSize: "12px",
                              "& .MuiSelect-select": {
                                borderRadius: "15px", // Rounded corners
                                transition: "0.3s", // Smooth transitions
                              },
                              "& .MuiSelect-select:hover": {
                                backgroundColor: "transparent", // Hover effect
                              },
                              "& .MuiSelect-icon": {
                                color: "transparent", // Change color scheme
                              },
                            }}
                            label="Review Template"
                          >
                            <MenuItem disabled value=" ">
                              {" "}
                            </MenuItem>
                            {TemplateMenu.map((templateType) => (
                              <MenuItem
                                key={templateType}
                                value={templateType}
                                sx={{
                                  fontSize: "12px",
                                  "&:hover": {
                                    backgroundColor: "#f5f5f5", // Hover effect
                                  },
                                }}
                              >
                                {templateType}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>
                      {/* <Grid item xs={12} sm={2}>
                        <TextField
                          fullWidth
                          sx={myTextFieldStyle}
                          label="Reviewer"
                          value={`${firstName} ${lastName}`}
                          InputProps={{
                            readOnly: true,
                            startAdornment: (
                              <InputAdornment position="start">
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    height: "2vh",
                                    width: "2vw",
                                    borderRadius: "50%",
                                    marginLeft: "-10px",
                                    backgroundColor: "transparent",
                                  }}
                                >
                                  <Avatar sx={{ width: 25, height: 25 }} />
                                </div>
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Grid> */}
                    </>
                  )}
                </Grid>
              ))}
            </Grid>
          </>
        )}
      </div>
    </Box>
  );
}

export default ManagerInterface;
