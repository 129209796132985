import { Box, Button, Typography, Divider, useMediaQuery } from "@mui/material";
import { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useUser } from "../../providers/UserContext";
import SchickCivilLogo from "../../assets/SchickCivilLogo.png";
import TexcoLogo from "../../assets/TexcoLogo.png";
import MDDLogo from "../../assets/MDDLogo.png";
import WDLogo from "../../assets/WDLogo.png";
import PPDLogo from "../../assets/PPDLogo.png";
import ShickGroupLogo from "../../assets/SchickGroupLogo.png";
import InsiteDigitalLogo from "../../assets/InsiteDigitalLogo_Black.svg";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { useAppContext } from "../../providers/AppContext";

const Section = ({ noTemplate, ReviewCompleted, children }) => {
  const matches = useMediaQuery("(min-width:600px)");
  const navigate = useNavigate();
  const [started, setStarted] = useState(false);
  const { firstName } = useUser();
  const { currentPage, updateCurrentPage, CompanyID } = useAppContext();
  const elementRef = useRef(null);

  const logoMapping = {
    1: ShickGroupLogo,
    2: SchickCivilLogo,
    3: TexcoLogo,
    4: WDLogo,
    5: PPDLogo,
    6: MDDLogo,
    9: InsiteDigitalLogo,
  };

  const logoStyles = {
    1: { width: matches ? "10vw" : "40vw", margin: "0 0 10px 0" },
    2: { width: matches ? "15vw" : "50vw", margin: "0 0 10px 0" },
    3: { width: matches ? "10vw" : "40vw", margin: "0 0 10px 0" },
    4: { width: matches ? "15vw" : "50vw", margin: "0 0 10px 0" },
    5: { width: matches ? "10vw" : "40vw", margin: "0 0 10px 0" },
    6: { width: matches ? "15vw" : "50vw", margin: "0 0 10px 0" },
    9: { width: matches ? "10vw" : "30vw", margin: "0 0 10px 0" },
  };

  useEffect(() => {
    if (elementRef.current) {
      elementRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  }, [currentPage]);

  const handleStart = () => {
    updateCurrentPage(1);
    setStarted(true);
  };

  const handleIconClick = () => {
    let previousPage = currentPage - 1;
    //console.log("Current Page:", currentPage);
    if (previousPage >= 1) {
      updateCurrentPage(previousPage);
      //console.log("previous Page: ", previousPage);
    } else if (previousPage === 0) {
      setStarted(false);
      updateCurrentPage(1);
    } else {
      updateCurrentPage(1);
      navigate("/");
    }
  };

  return (
    <Box
      sx={{
        backgroundColor: "rgba(255, 255, 255, 0.85)",
        width: ["80%", "70%", "60%"],
        height: ["75vh", "80vh", "80vh"],
        marginLeft: ["2%", "15%", "20%"], // Center the container
        boxShadow:
          "0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04)",
        borderRadius: "1.5rem",
        position: "absolute", // Change this to absolute
        top: ["10%", "5%", "7%"], // Adjust this value to position the container relative to the top of the screen
        zIndex: 2,
        display: "flex",
        justifyContent: "flex-start",
        flexDirection: "column",
        alignItems: "flex-start",
        padding: "2rem",
        color: "black",
        overflowY: "auto",
        "&::-webkit-scrollbar": {
          width: "0.2em",
        },
        "&::-webkit-scrollbar-track": {
          WebkitBoxShadow: "inset 0 0 3px rgba(0,0,0,0.00)",
        },
        "&::-webkit-scrollbar-thumb": {
          backgroundColor: "rgba(0,0,0,.1)",
        },
        "&:hover::-webkit-scrollbar-thumb": {
          backgroundColor: "rgba(0,0,0,.4)",
        },
      }}
    >
      {started ? (
        <ArrowBackIosNewIcon
          sx={{
            position: "absolute",
            top: 10,
            left: 5,
            fontSize: "1.25rem",
            color: "black",
          }}
          onClick={handleIconClick}
          ref={elementRef}
        />
      ) : null}
      {!started ? (
        <>
          <img
            src={logoMapping[CompanyID]}
            alt="Company Logo"
            style={logoStyles[CompanyID]}
          />
          <Typography
            variant={matches ? "h4" : "h6"}
            gutterBottom
            sx={{ fontSize: ["1.2rem", "1.5rem", "1.8rem"] }}
          >
            Your Performance Review 2024
          </Typography>
          <Divider />
          <Typography
            variant={matches ? "body1" : "body2"}
            gutterBottom
            sx={{ fontSize: ["0.8rem", "1rem", "1rem"] }}
          >
            Hi {firstName},
          </Typography>
          {(noTemplate || ReviewCompleted) ? (
            <>
            <Typography
                variant={matches ? "body1" : "body2"}
                gutterBottom
                sx={{ fontSize: ["0.8rem", "1rem", "1rem"] }}
              >
                The performance review is a valuable opportunity to reflect on
                your accomplishments and areas for development over the past 12
                months. By thoughtfully answering the questions below, you’ll
                help your manager schedule a meaningful discussion with you and
                shape our support for the year ahead.
              </Typography>
              {ReviewCompleted ? (
               <Typography
               variant={matches ? "body1" : "body2"}
               gutterBottom
               sx={{ fontSize: ["0.8rem", "1rem", "1rem"], fontWeight: "bold", color: "red", marginTop: "1rem"}}
             >
              You have already submitted a review. Please talk to your manager if you need to make any changes.
             </Typography>  
              ) : (
              <Typography
                variant={matches ? "body1" : "body2"}
                gutterBottom
                sx={{ fontSize: ["0.8rem", "1rem", "1rem"], fontWeight: "bold", color: "red", marginTop: "1rem"}}
              >
                You don't have a performance review scheduled yet. Please talk
                to your manager to set one up.
              </Typography>
               )}
            </>
          ) : (
            <>
              <Typography
                variant={matches ? "body1" : "body2"}
                gutterBottom
                sx={{ fontSize: ["0.8rem", "1rem", "1rem"] }}
              >
                The performance review is a valuable opportunity to reflect on
                your accomplishments and areas for development over the past 12
                months. By thoughtfully answering the questions below, you’ll
                help your manager schedule a meaningful discussion with you and
                shape our support for the year ahead.
              </Typography>
              <Typography
                variant={matches ? "body1" : "body2"}
                gutterBottom
                component="div"
                sx={{ fontSize: ["0.8rem", "1rem", "1rem"] }}
              >
                <b>Four Key Sections:</b>
                <ul
                  style={{
                    textAlign: "left",
                    listStylePosition: "inside",
                    paddingLeft: 0,
                  }}
                >
                  <li style={{ textIndent: "-20px", paddingLeft: "20px" }}>
                    Doing the Basics Right (OTOBOSS).
                  </li>
                  <li style={{ textIndent: "-20px", paddingLeft: "20px" }}>
                    High Performing Teams
                  </li>
                  <li style={{ textIndent: "-20px", paddingLeft: "20px" }}>
                    Values and Team Player
                  </li>
                  <li style={{ textIndent: "-20px", paddingLeft: "20px" }}>
                    General Comments
                  </li>
                </ul>
              </Typography>
              <Typography
                variant={matches ? "body1" : "body2"}
                gutterBottom
                sx={{ fontSize: ["0.8rem", "1rem", "1rem"] }}
              >
                Remember, this review is not a sprint; it’s a marathon. Take
                your time—approximately 20 minutes—to provide thoughtful
                responses. You can pause and return later using the link sent
                via email.
              </Typography>
            </>
          )}
          <Button
            disabled={(noTemplate || ReviewCompleted)}
            variant="contained"
            color="primary"
            style={{ width: "100%" }}
            onClick={handleStart}
            sx={{ marginTop: "1rem" }}
          >
            Start Review
          </Button>
        </>
      ) : (
        children
      )}
    </Box>
  );
};

export default Section;