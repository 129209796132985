import React from "react";
import {
    useMediaQuery,
    Avatar,
    Box,
    TextareaAutosize,
    InputLabel,
    OutlinedInput,
} from "@mui/material";
import useUserDetails from "../hooks/useUserDetails";

export default function UserComment({ userType, CommentUser, CommentValue }) {
    const matches = useMediaQuery("(min-width:600px)");
    const { managerFirstName, managerPicture } = useUserDetails();

    const CustomTextarea = React.forwardRef(({ avatarSrc, ...props }, ref) => (
        <Box display="flex" alignItems="center">
            <TextareaAutosize
                {...props}
                ref={ref}
                style={{ flexGrow: 1, width: matches ? "40vw" : "55vw" }}
            />
        </Box>
    ));

    return (
        <Box
            sx={{
                position: "relative",
                display: "flex",
                alignItems: "center",
                mt: 2,
                mb: 2,
                mr: 2,
                ml: 2,
            }}
        >
            {managerPicture && (
                <Avatar
                    src={managerPicture}
                    style={{ borderRadius: "50px", marginRight: "10px" }}
                />
            )}
            <InputLabel
                shrink
                htmlFor="comment"
                sx={{
                    position: "absolute",
                    left: "60px", // Adjust this value as needed
                    top: "-6px", // Adjust this value as needed
                    backgroundColor: "#fff",
                    paddingLeft: "5px",
                    paddingRight: "5px",
                    zIndex: 1, // Add this line
                }}
            >
            {userType === "manager" ? `${managerFirstName}'s Comment` : "Employee's Comment"}
            </InputLabel>
            <OutlinedInput
                id="comment"
                value={CommentValue}
                readOnly
                multiline
                rows={1}
                notched
                inputComponent={CustomTextarea}
                sx={{
                    width: "100%",
                    backgroundColor: "#E1EFF6",
                    fontSize: "0.875rem",
                    borderRadius: "15px",
                    "& .MuiOutlinedInput-notchedOutline": {
                        borderColor: "grey",
                    },
                }}
            />
            </Box>
    );
}