
const api_endpoint = process.env.REACT_APP_API_ENDPOINT;
const api_endpoint_key = process.env.REACT_APP_API_KEY;

export async function getCompanyID(companyUPN) {
  const url = `${api_endpoint}/companyList/${encodeURIComponent(companyUPN)}?code=${api_endpoint_key}&clientId=CIVILX`;
  try {
    const response = await fetch(url);

    // Check if the request was successful
    if (!response.ok) {
      console.error('Request failed:', response.status, response.statusText);
      return;
    }

    // Check if the response body is not empty
    if (!response.body) {
      console.error('Empty response body');
      return;
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error:', error);
  }
}

// Function to get manager comments for user
export async function getManagerUserComment(managerId, userId) {
    const url = `${api_endpoint}/performanceReview/Manager?managerId=${managerId}&userId=${userId}&code=${api_endpoint_key}&clientId=CIVILX`;
    try {
        const response = await fetch(url);
        const data = await response.json();
        return data;
    } catch (error) {
        return console.error('Error:', error);
    }
}

export async function getManagerAllComments(managerId) {
    const url = `${api_endpoint}/performanceReview/Manager?managerId=${managerId}&code=${api_endpoint_key}&clientId=CIVILX`;
    try {
        const response = await fetch(url);
        const data = await response.json();
        return data;
    } catch (error) {
        return console.error('Error:', error);
    }
}

export async function postNewComment(managerId, userId, commentData, method) {
    const url = `${api_endpoint}/performanceReview/${managerId}/comment/${userId}?code=${api_endpoint_key}`;
    // console.log("URL:", url);
    console.log("Method:", method);
    // console.log("Request Body:", JSON.stringify(commentData));
    try {
        const response = await fetch(url, {
            method: method,
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(commentData)
        });
        const data = await response.json();
        return data;
    } catch (error) {
        console.error('Error:', error);
        console.error('Response:', error.response);
    }
}

// Function to get Templates for user
export async function getReviewTemplates() {
    const url = `${api_endpoint}/performanceReview/Templates?code=${api_endpoint_key}&clientId=CIVILX`;
    try {
        const response = await fetch(url);
        const data = await response.json();
        return data;
    } catch (error) {
        return console.error('Error:', error);
    }
}

export async function postUserReview(reviewData) {
    const url = `${api_endpoint}/userReview/?code=${api_endpoint_key}`;
    try {
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(reviewData)
        });
        const data = await response.json();
        return data;
    } catch (error) {
        console.error('Error:', error);
        console.error('Response:', error.response);
    }
}

// Function to get a User Review
export async function getUserReview(sid) {
    const url = `${api_endpoint}/userReview?employeeId=${sid}&code=${api_endpoint_key}&clientId=CIVILX`;
    try {
        const response = await fetch(url);
        const data = await response.json();
        return data;
    } catch (error) {
        return console.error('Error:', error);
    }
}
