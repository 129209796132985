import { useEffect } from 'react';
import { useMsal } from '@azure/msal-react';
import { useUser } from "../providers/UserContext";
import { getUserInfo, getUserPicture, fetchUserManager } from "../hooks/usefetchOtherUserInfo";



const useUserDetails = () => {
    const { instance, accounts } = useMsal();
    const {
        profilePicture, setProfilePicture,
        firstName, setFirstName,
        lastName, setLastName,
        email, setEmail,
        jobTitle, setJobTitle,
        mobilePhone, setMobilePhone,
        department, setDepartment,
        officeLocation, setOfficeLocation,
        city, setCity,
        companyName, setCompanyName,
        userRole, setUserRole,
        manager, setManager,
        managerid, setManagerid,
        managerPicture, setManagerPicture,
        managerFirstName, setManagerFirstName,
        managerLastName, setManagerLastName,
        sid, setsid,
    } = useUser();

    useEffect(() => {
        const getUserDetails = async () => {
            if (accounts.length === 0) {
                setProfilePicture(null);
                setFirstName(null);
                setLastName(null);
                setEmail(null);
                setJobTitle(null);
                setMobilePhone(null);
                setDepartment(null);
                setOfficeLocation(null);
                setCity(null);
                setCompanyName(null);
                setUserRole(null);
                setManager(null);
                setManagerPicture(null);
                setManagerFirstName(null);
                setManagerLastName(null);
                setsid(null);
                return;
            }


            try {
                const accessTokenResponse = await instance.acquireTokenSilent({
                    scopes: ["user.read"],
                    account: accounts[0],
                });

                const accessToken = accessTokenResponse.accessToken;
                const response = await fetch("https://graph.microsoft.com/v1.0/me?$select=id,displayName,givenName,surname,mail,jobTitle,mobilePhone,department,officeLocation,city,companyName", {
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                    },
                });

                if (response.ok) {
                    const data = await response.json();
                    setsid(data.id);
                    setFirstName(data.givenName);
                    setLastName(data.surname);
                    setEmail(data.mail);
                    setJobTitle(data.jobTitle);
                    setMobilePhone(data.mobilePhone);
                    setDepartment(data.department);
                    setOfficeLocation(data.officeLocation);
                    setCity(data.city);
                    setCompanyName(data.companyName);
                    setUserRole(data.userRole);
                    localStorage.setItem('userId', data.id);
                    localStorage.setItem('userFirstName', data.givenName);
                    localStorage.setItem('userLastName', data.surname);
                    localStorage.setItem('userEmail', data.mail);
                    localStorage.setItem('userJobTitle', data.jobTitle);
                    localStorage.setItem('userMobilePhone', data.mobilePhone);
                    localStorage.setItem('userDepartment', data.department);
                    localStorage.setItem('userOfficeLocation', data.officeLocation);
                    localStorage.setItem('userCity', data.city);
                    localStorage.setItem('userCompanyName', data.companyName);
                    localStorage.setItem('userUserRole', data.userRole);
                }
            } catch (error) {
                console.error("Error fetching user details:", error);
            }
        };

        getUserDetails();

        if (!profilePicture) {
            const getProfilePicture = async () => {
                try {
                    const accessTokenResponse = await instance.acquireTokenSilent({
                        scopes: ["user.read"],
                        account: accounts[0],
                    });

                    const accessToken = accessTokenResponse.accessToken;
                    const response = await fetch("https://graph.microsoft.com/v1.0/me/photo/$value", {
                        headers: {
                            Authorization: `Bearer ${accessToken}`,
                        },
                    });

                    if (response.ok) {
                        const blob = await response.blob();
                        const reader = new FileReader();
                        reader.onloadend = () => {
                            let base64String = reader.result;
                            setProfilePicture(base64String);
                            localStorage.setItem('userProfilePicture', base64String);
                        };
                        reader.readAsDataURL(blob);
                    } else {
                        // If the response is not ok, generate a URL for a default avatar
                        const defaultAvatarUrl = `https://avatar.iran.liara.run/username?username=${firstName}+${lastName}&size=512&background=0D8ABC&color=fff&rounded=true&bold=true&font=arial&smooth=true&format=svg`;
                        setProfilePicture(defaultAvatarUrl);
                        localStorage.setItem('userProfilePicture', defaultAvatarUrl);
                    }
                } catch (error) {
                    console.error("Error fetching profile picture:", error);
                }
            };

            getProfilePicture();
        }
        if (sid) {
            fetchUserManager(sid).then((data) => {
                setManager(data.displayName);
                localStorage.setItem('manager', data.displayName);
            });
        }

        if (!managerPicture && manager) {
            getUserInfo(manager).then((data) => {
                const userId = data[0].id; // Assuming the ID is in the first object of the array
                setManagerFirstName(data[0].givenName);
                setManagerLastName(data[0].surname);
                localStorage.setItem('managerFirstName', managerFirstName);
                localStorage.setItem('managerLastName', managerLastName);
                localStorage.setItem('managerid', userId);
                setManagerid(userId);
                getUserPicture(userId).then((pictureUrl) => {
                    if (pictureUrl === 'no picture') {
                        // If no picture is returned, set managerPicture to a default image
                        pictureUrl = `https://avatar.iran.liara.run/username?username=${managerFirstName}+${managerLastName}&size=512&background=0D8ABC&color=fff&rounded=true&bold=true&font=arial&smooth=true&format=svg`;
                    }
                    setManagerPicture(pictureUrl);
                    localStorage.setItem('managerPicture', pictureUrl);
                });
            });
        }
        // eslint-disable-next-line
    }, [instance, accounts, profilePicture, sid, manager, managerPicture, managerFirstName, managerLastName, managerid, firstName, lastName, email, jobTitle, companyName]);

    return { profilePicture, firstName, lastName, email, jobTitle, mobilePhone, department, officeLocation, city, companyName, userRole, manager, managerPicture, managerFirstName, managerLastName, sid, managerid };
};

export default useUserDetails;