import React, { useState, useEffect } from "react";
import {
  Typography,
  Button,
  OutlinedInput,
  useMediaQuery,
  InputLabel,
  FormControl,
} from "@mui/material";
import { useUser } from "../providers/UserContext";
import { useAppContext } from "../providers/AppContext";
import { QuestionStyles } from "../styles/GlobalStyles";
import { getManagerUserComment, getReviewTemplates, postNewComment, } from "../hooks/usefetchCivilxApi";
import { Loading } from "../components/loading";

function CreateComments({ userSid, QuestionTemplate, onCommentCreated, method }) {
  const [comments, setComments] = useState([]);
  const matches = useMediaQuery("(min-width:600px)");
  const { currentPage, updateCurrentPage, CompanyID } = useAppContext();
  const [currentSection, setCurrentSection] = useState(0);
  const [sortedSections, setSortedSections] = useState([]);
  const { sid } = useUser();
  const [isLoading, setIsLoading] = React.useState(true);


useEffect(() => {
  updateCurrentPage(1);
  const foundUserTemplate = QuestionTemplate;
  console.log("Template:", foundUserTemplate);

  getReviewTemplates().then(response => {
    const templateRecord = response.find(item => item.record.Templates.some(template => template.Type === foundUserTemplate));
    const template = templateRecord ? templateRecord.record.Templates.find(template => template.Type === foundUserTemplate) : null;

    if (template) {
      getManagerUserComment(sid, userSid).then(ManagerComments => {
        const storedComments = JSON.parse(localStorage.getItem(userSid));

        if (storedComments && storedComments.QuestionTemplate === QuestionTemplate) {
          // If there are stored comments and they match the current QuestionTemplate, use them
          setComments(storedComments);
        } else {
          const initialComments = template.Sections.flatMap((section) =>
            section.SectionQuestions.map((question) => ({
              Question: question.Question,
              CommentValue: "",
              MyNotes: "",
            }))
          );

          setComments({
            manager: sid,
            id: userSid,
            QuestionTemplate: QuestionTemplate,
            Questions: initialComments,
          });
        }
        // Sort the sections by SectionNumber
        const sortedSections = template.Sections.sort(
          (a, b) => a.SectionNumber - b.SectionNumber
        );
        setSortedSections(sortedSections); // assuming you have a state for sortedSections
        // Get the current section
        setCurrentSection(sortedSections[currentPage - 1]);
      });
    }
  });
  setTimeout(() => {
    setIsLoading(false);
  }, 1000);
  // eslint-disable-next-line
}, [QuestionTemplate]);


  useEffect(() => {
    // Check if currentPage is a valid index for sortedSections
    if (currentPage >= 1 && currentPage <= sortedSections.length) {
      // Update the current section when the currentPage changes
      setCurrentSection(sortedSections[currentPage - 1]);
    } else {
      // Handle the case where currentPage is not a valid index
      // This could involve setting currentPage to a valid value, or showing an error message
    }
  // eslint-disable-next-line
  }, [currentPage]);


const handleCommentChange = (id, questionNumber, newComment) => {
  setComments((prevComments) => {
    const newComments = {
      ...prevComments,
      Questions: prevComments.Questions.map((comment) =>
        comment.Question === questionNumber
          ? { ...comment, CommentValue: newComment }
          : comment
      ),
    };

    // Store the new comments in localStorage
    localStorage.setItem(id, JSON.stringify(newComments));

    return newComments;
  });
};

const handleNoteChange = (id, questionNumber, newNote) => {
  setComments((prevComments) => {
    const newComments = {
      ...prevComments,
      Questions: prevComments.Questions.map((comment) =>
        comment.Question === questionNumber
          ? { ...comment, MyNotes: newNote }
          : comment
      ),
    };

    // Store the new comments in localStorage
    localStorage.setItem(id, JSON.stringify(newComments));

    return newComments;
  });
};

const handleSubmit = () => {
  // Go to the next page if it's not the last page
  if (currentPage < sortedSections.length) {
    updateCurrentPage(currentPage + 1);
    setCurrentSection(sortedSections[currentPage]); // Update the current section here
    window.scrollTo(0, 0); // Scroll to the top of the screen
  } else {
    // This is the last page, perform the submit operation

    // Retrieve the subset of comments from localStorage
    const commentsSubset = JSON.parse(localStorage.getItem(userSid));

    // Create a new comments object using the retrieved data
    const comments = {
      CompanyID: CompanyID,
      record: {
        Manager: commentsSubset.manager,
        Comments: [
          {
            id: commentsSubset.id,
            QuestionTemplate: commentsSubset.QuestionTemplate,
            Questions: commentsSubset.Questions,
          },
        ],
      },
      type: "Manager",
    };

    postNewComment(sid, userSid, comments, method)
      .then((data) => {
        console.log(data);
        console.log("Comment posted successfully:");
        // After data is submitted, call the onCommentCreated function
        localStorage.removeItem(userSid);
        onCommentCreated();
        updateCurrentPage(1);
      })
      .catch((error) => {
        console.error("Error posting comment:", error);
      });
  }
};

  return (
    <div >
      {isLoading && <Loading message="Retrieving Comments... Please wait" />}
      <Typography variant={matches ? "h5" : "h6"} gutterBottom>
        {currentSection.SectionTitle}
      </Typography>
      <Typography
        variant={matches ? "body1" : "body2"}
        gutterBottom
        sx={{ fontStyle: "italic", fontSize: matches ? "1rem" : "0.875rem" }}
      >
        {currentSection.SectionSubTite}
      </Typography>
      {currentSection &&
        currentSection.SectionQuestions &&
        currentSection.SectionQuestions.sort(
          (a, b) => a.Question - b.Question
        ).map((questionData, questionIndex) => {
          const comment = comments.Questions.find(
            (c) => c.Question === questionData.Question
          );
          return (
            <div key={questionIndex}>
              <Typography variant="body2" sx={QuestionStyles}>
                {`${questionData.Question}. ${questionData.QuestionText}`}
              </Typography>
              <FormControl
                variant="outlined"
                sx={{ width: "99%", mt: 2, mb: 2 }}
              >
                <InputLabel htmlFor="manager-comment">
                  Manager Comments (Visable to Staff)
                </InputLabel>
                <OutlinedInput
                  id="manager-comment"
                  value={comment ? comment.CommentValue : ""}
                  onChange={(e) =>
                    handleCommentChange(
                      userSid,
                      questionData.Question,
                      e.target.value
                    )
                  }
                  onBlur={(e) =>
                    handleCommentChange(
                      userSid,
                      questionData.Question,
                      e.target.value
                    )
                  }
                  multiline
                  rows={4}
                  sx={{
                    width: "99%",
                    mt: 2,
                    mb: 2,
                    backgroundColor: "#E1EFF6",
                    fontSize: "0.875rem",
                    color: "black",
                    borderRadius: "10px",
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderColor: "grey",
                    },
                  }}
                />
              </FormControl>
              <FormControl
                variant="outlined"
                sx={{ width: "99%", mt: 0, mb: 1 }}
              >
                <InputLabel htmlFor="manager-notes">
                  Manager Notes (Confidential comments for managerial use only)
                </InputLabel>
                <OutlinedInput
                  id="manager-notes"
                  value={comment ? comment.MyNotes : ""}
                  onChange={(e) =>
                    handleNoteChange(
                      userSid,
                      questionData.Question,
                      e.target.value
                    )
                  }
                  onBlur={(e) =>
                    handleNoteChange(
                      userSid,
                      questionData.Question,
                      e.target.value
                    )
                  }
                  multiline
                  rows={4}
                  sx={{
                    width: "99%",
                    mt: 2,
                    mb: 2,
                    backgroundColor: "#f2f2f2",
                    fontSize: "0.875rem",
                    borderRadius: "10px",
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderColor: "grey",
                    },
                  }}
                />
              </FormControl>
            </div>
          );
        })}
      <div style={{ display: "flex", justifyContent: "center" }}>
        <Button
          variant="contained"
          color="primary"
          style={{ width: "90%", marginBottom: "2rem" }}
          onClick={handleSubmit}
        >
          {currentPage < sortedSections.length ? "Next" : "Submit"}
        </Button>
      </div>
    </div>
  );
}

export default CreateComments;
