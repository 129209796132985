import React, { useState, useEffect } from "react";
import {
  ImageList,
  ImageListItem,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  InputAdornment,
  MenuItem,
} from "@mui/material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import PhotoCameraIcon from "@mui/icons-material/PhotoCamera";
import ClearIcon from "@mui/icons-material/Clear";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import Popover from "@mui/material/Popover";
import fileicon from '../assets/file.svg'

const UploadComponent = ({ mode, iconcolor, dbtype, tag }) => {
  const [files, setFiles] = useState(() => {
    // Retrieve files from local storage during the initial mount
    const storedFiles = JSON.parse(localStorage.getItem("uploadedFiles")) || [];
    return storedFiles;
  });
  const [open ] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [ setDialogOpen] = useState(false);
  const [popoverOpen, setPopoverOpen] = useState(false);
  const inputFileRefPhotos = React.useRef(null);
  const inputFileRefDocs = React.useRef(null);
  //const isPhotoMode = mode !== "file";

  const MAX_FILES_LIMIT = 5; // Maximum number of files that can be uploaded

  const openDatabase = () => {
    return new Promise((resolve, reject) => {
      const request = indexedDB.open("myReview", 1);
      request.onupgradeneeded = (event) => {
        const db = event.target.result;
        const objectStores = ['ReviewFiles', 'ReviewData']; // All possible object stores

        objectStores.forEach((storeName) => {
          if (!db.objectStoreNames.contains(storeName)) {
            db.createObjectStore(storeName, { autoIncrement: true });
          }
        });
      };
      request.onsuccess = (event) => {
        resolve(event.target.result);
      };
      request.onerror = (event) => {
        reject(event.target.error);
      };
    });
  };

  const storeFiles = async (files, tag) => {
    const db = await openDatabase();
    const transaction = db.transaction(dbtype, "readwrite");
    const filesStore = transaction.objectStore(dbtype);

    const keys = [];
    for (const file of files) {
      const taggedFile = { file, tag }; // Add the tag to the file
      const request = filesStore.add(taggedFile);
      request.onsuccess = () => keys.push(request.result);
    }

    await new Promise((resolve, reject) => {
      transaction.oncomplete = () => resolve(keys);
      transaction.onerror = () => reject(transaction.error);
    });
    console.log(keys);
    return keys;
  };

  const getFiles = async () => {
    const db = await openDatabase();
    if (!db.objectStoreNames.contains(dbtype)) {
      throw new Error(`Object store ${dbtype} does not exist`);
    }
    const transaction = db.transaction(dbtype, 'readonly');
    const filesStore = transaction.objectStore(dbtype);

    return new Promise((resolve, reject) => {
      const request = filesStore.openCursor();
      const files = [];

      request.onerror = () => reject(request.error);
      request.onsuccess = () => {
        const cursor = request.result;
        if (cursor) {
          files.push({ key: cursor.key, value: cursor.value });
          cursor.continue();
        } else {
          resolve(files);
        }
      };
    });
  };

  const deleteFile = async (id) => {
    if (id === undefined || id === null) {
      console.error("Invalid id:", id);
      return;
    }

    openDatabase()
      .then((db) => {
        let transaction = db.transaction(dbtype, "readwrite");
        let filesStore = transaction.objectStore(dbtype);

        let request = filesStore.delete(id);
        request.onsuccess = function () {
          console.log("Delete operation successful.");
        };

        request.onerror = function () {
          console.log("Error", request.error);
        };
      })
      .catch((error) => {
        console.log("Error", error);
      });
  };

  const resetDatabase = async () => {
    const db = await openDatabase();
    const transaction = db.transaction(dbtype, "readwrite");
    const filesStore = transaction.objectStore(dbtype);

    filesStore.clear();

    return new Promise((resolve, reject) => {
      transaction.oncomplete = () => resolve();
      transaction.onerror = () => reject(transaction.error);
    });
  };

  useEffect(() => {
    // Retrieve files from IndexedDB when component mounts
    const loadFiles = async () => {
      const storedFiles = await getFiles();
      console.log(storedFiles);
      setFiles(
        storedFiles.map((file) => ({
          key: file.key,
          url: URL.createObjectURL(file.value.file), // Access the File object
          file: file.value.file, // Access the File object
          tag: file.value.tag, // Access the tag
        }))
      );
    };

    loadFiles();
    // eslint-disable-next-line
  },[]);

  useEffect(() => {
    // Store files in local storage whenever the files state changes
    localStorage.setItem("uploadedFiles", JSON.stringify(files));
  }, [files]);

  const handleFileUpload = async (event, tag) => {
    console.log("Hello world");
    const filesArray = Array.from(event.target.files);
    if (filesArray.length + files.length > MAX_FILES_LIMIT) {
      alert(
        `Maximum allowed files is ${MAX_FILES_LIMIT}. You tried to upload ${
          filesArray.length + files.length
        } files.`
      );
      return;
    }

    // Store the uploaded files in IndexedDB
    const keys = await storeFiles(filesArray, tag);

    // Create URLs for the files
    const fileURLs = await Promise.all(
      filesArray.map((file) => {
        if (file.type.startsWith("image/")) {
          return URL.createObjectURL(file);
        } else {
          return readFileAsDataURL(file);
        }
      })
    );

    // Add the files to the files state
    setFiles((prevFiles) => [
      ...prevFiles,
      ...fileURLs.map((url, index) => ({
        url,
        file: filesArray[index],
        key: keys[index],
      })),
    ]);
  };

  function readFileAsDataURL(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.onerror = reject;
      reader.readAsDataURL(file);
    });
  }

  const handleDeleteFile = async (index) => {
    // Get the key of the file to delete
    const fileToDelete = files[index];

    console.log("File Delete:", fileToDelete.key);

    // Delete the file from IndexedDB
    await deleteFile(fileToDelete.key);

    // Remove the file from the files state
    setFiles((prevFiles) => {
      const newFiles = prevFiles.filter((_, i) => i !== index);

      // If the file being deleted is the last one in the files array, close the dialog
      if (newFiles.length === 0) {
        handleCloseDialog();
      }

      return newFiles;
    });
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setPopoverOpen(true);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setPopoverOpen(false);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
    setSelectedFile(null);
    setPopoverOpen(false)
  };

  const handleReset = async () => {
    if (
      window.confirm(
        "Are you sure you want to delete all files? This action cannot be undone."
      )
    ) {
      await resetDatabase();
      setFiles([]);
    }
  };

  const popid = open ? "simple-popover" : undefined;

  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      {mode === "file" ? (
        <IconButton
          component="span"
          style={{
            width: "35vw",
            margin: "10px 10px 20px 0px",
            height: "4vh",
            maxWidth: "200px",
            fontSize: "0.9em",
            borderRadius: "50px",
            backgroundColor: "white",
            justifyContent: "flex-start",
            border: "1px solid lightgrey",
          }}
          onClick={handleClick}
        >
          <InputAdornment position="start">
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "3vh",
                width: "3vh",
                borderRadius: "50%",
                marginRight: "5px",
                marginLeft: "-3px",
                backgroundColor: iconcolor ? iconcolor : "gray",
              }}
            >
              <CloudUploadIcon
                style={{
                  color: "white",
                  height: "2vh",
                }}
              />
            </div>
          </InputAdornment>
          <span>Upload</span>
        </IconButton>
      ) : (
        <label htmlFor="contained-button-file">
          <IconButton
            component="span"
            style={{
              width: "35vw",
              margin: "20px 10px 20px 20px",
              height: "4vh",
              fontSize: "0.9em",
              borderRadius: "25px",
              backgroundColor: "white",
              justifyContent: "flex-start",
              border: "1px solid lightgrey",
            }}
          >
            <InputAdornment position="start">
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "4vh",
                  width: "4vh",
                  borderRadius: "50%",
                  marginRight: "5px",
                  marginLeft: "-3px",
                  backgroundColor: iconcolor ? iconcolor : "gray",
                }}
              >
                <PhotoCameraIcon
                  style={{
                    color: "white",
                    height: "3vh",
                  }}
                />
              </div>
            </InputAdornment>
            <span>Add Photos</span>
          </IconButton>
          <input
            accept="image/*"
            id="contained-button-file"
            multiple
            type="file"
            style={{ display: "none" }}
            onChange={handleFileUpload}
          />
        </label>
      )}
      <Popover
        id={popid}
        open={popoverOpen}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <label
          htmlFor="image-upload"
          onClick={() => console.log("Label clicked")}
        >
          <MenuItem
            onClick={(event) => {
              event.preventDefault();
              inputFileRefPhotos.current.click();
            }}
          >
            <PhotoCameraIcon />
            <span style={{ marginLeft: "5px" }}>Add Photos</span>
          </MenuItem>
          <input
            ref={inputFileRefPhotos}
            accept="image/*"
            id="image-upload"
            multiple
            type="file"
            style={{ display: "none" }}
            onChange={(event) => {
              handleFileUpload(event);
              handleClose();
            }}
          />
        </label>
        <label htmlFor="document-upload">
          <MenuItem
            onClick={(event) => {
              event.preventDefault();
              inputFileRefDocs.current.click();
            }}
          >
            <CloudUploadIcon />
            <span style={{ marginLeft: "5px" }}>Add Documents</span>
          </MenuItem>
          <input
            ref={inputFileRefDocs}
            accept=".pdf,.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
            id="document-upload"
            multiple
            type="file"
            style={{ display: "none" }}
            onChange={(event) => {
              handleFileUpload(event);
              handleClose();
            }}
          />
        </label>
      </Popover>

      {/* Small Thumbnail Galley next to the Button */}
        <ImageList
          style={{ display: "flex", flexWrap: "nowrap", overflowX: "auto" }}
        >
          {files.map((file) => (
            <ImageListItem key={file.key} onClick={() => setSelectedFile(file)}>
              <img
                src={file.file && file.file.type && file.file.type.startsWith('image') ? file.url : fileicon}
                alt={`file-${file.key}`}
                style={{ width: "4vh", height: "4vh" }}
              />
            </ImageListItem>
          ))}
        </ImageList>


      {/* Dialog for File Viewer */}
      <Dialog open={!!selectedFile} onClose={handleCloseDialog} fullScreen>
        <DialogTitle></DialogTitle>
        <IconButton
          onClick={handleCloseDialog}
          style={{ position: "absolute", left: 0, top: 0 }}
        >
          <ArrowBackIosNewIcon
            style={{
              height: "3vh",
            }}
          />
        </IconButton>

        <DialogContent>
          {files.map((file, index) => {
            return (
              <div
                key={index}
                style={{
                  position: "relative",
                  marginBottom: "10px",
                }}
              >
                {file.file && file.file.type && file.file.type.startsWith('image') ? (
                  <>
                  <img
                    src={file.url}
                    alt={`file-${index}`}
                    style={{ width: "100%", height: "auto" }}
                  />
                  <IconButton
                  onClick={(event) => {
                    event.stopPropagation();
                    handleDeleteFile(index);
                  }}
                  style={{
                    position: "absolute",
                    top: 0,
                    right: 0,
                    color: file.file && file.file.type && file.file.type.startsWith('image') ? "white" : "black"
                  }}
                >
                  <ClearIcon />
                </IconButton>
                </>
                ) : (
                  <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <div style={{ display: 'flex', alignItems: 'center', fontSize: '3vw' }}>
                      <img
                        src={fileicon}
                        alt='file-icon'
                        style={{ width: '4vw', height: '4vw', marginRight: '10px' }}
                      />
                      {file.file.name}
                    </div>
                    <IconButton
                      onClick={(event) => {
                        event.stopPropagation();
                        handleDeleteFile(index);
                      }}
                      style={{
                        color: file.file && file.file.type && file.file.type.startsWith('image') ? "white" : "black"
                      }}
                    >
                      <ClearIcon />
                    </IconButton>
                  </div>
                )}
              </div>
            );
          })}
        </DialogContent>
        <DialogActions style={{ justifyContent: "center" }}>
          <IconButton
            onClick={handleReset}
            style={{
              width: "35vw",
              margin: "0px 0px 20px 0px",
              height: "4vh",
              fontSize: "0.9em",
              borderRadius: "25px",
              backgroundColor: "white",
              justifyContent: "flex-start",
              border: "1px solid lightgrey",
            }}
          >
            <InputAdornment position="start">
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "4vh",
                  width: "4vh",
                  borderRadius: "50%",
                  marginRight: "5px",
                  marginLeft: "-3px",
                  backgroundColor: iconcolor ? iconcolor : "gray",
                }}
              >
                <DeleteForeverIcon
                  style={{
                    color: "white",
                    height: "3vh",
                  }}
                />
              </div>
            </InputAdornment>
            <span>Delete All</span>
          </IconButton>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default UploadComponent;
