//Global Styles

export const QuestionStyles = {
    fontWeight: "bold",
    width: "99%",
    minWidth: '99%'
};

export const QuestionStylesSubText = {
    fontStyle: "italic",
    //fontSize: "0.85rem",
    width: "99%",
};


