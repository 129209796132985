import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { MsalProvider } from '@azure/msal-react';
import msalInstance from './auth/msalInstance'

if(navigator.userAgent.indexOf('iPhone') > -1 )
{
    document
      .querySelector("[name=viewport]")
      .setAttribute("content","width=device-width, initial-scale=1, maximum-scale=1");
}
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <React.StrictMode>
    <MsalProvider instance={msalInstance}>
        <App />
    </MsalProvider>
  // </React.StrictMode>
);
reportWebVitals();