import React, { useState, useEffect, useRef } from "react";
import { Typography, useMediaQuery, Box, Button } from "@mui/material";
import MultichoiceQuestion from "../MultiChoiceQuestion";
import WrittenAnswerQuestion from "../WrittenQuestions";
import FileUploadQuestion from "../FileUploadQuestion";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import useMyGeolocation from "../../hooks/useGeoLocation";

import { useAppContext } from "../../providers/AppContext";
import { useUser } from "../../providers/UserContext";
import { postUserReview } from "../../hooks/usefetchCivilxApi";

function ReviewForm({ QuestionTemplate, CommentsData }) {
  const matches = useMediaQuery("(min-width:600px)");
  const {
    currentPage,
    updateCurrentPage,
    formData,
    updateFormData,
    CompanyID,
  } = useAppContext();
  const { lat, lon } = useMyGeolocation();
  const [unansweredQuestions, setUnansweredQuestions] = useState([]);
  const { sid, manager } = useUser();
  const elementRef = useRef(null);
  const [isSuccess, setIsSuccess] = useState(false);
  console.log("Location : ", lat, lon);

  useEffect(() => {
    if (elementRef.current) {
      elementRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  }, [currentPage]);

  function Questionnaire() {
    // Create a mapping of comments by question number
    const commentsByQuestion = {};
    if (CommentsData && CommentsData.Questions) {
      CommentsData.Questions.forEach((comment) => {
        commentsByQuestion[comment.Question] = comment;
      });
    }
    // Sort the sections by SectionNumber
    const sortedSections = QuestionTemplate.Sections.sort(
      (a, b) => a.SectionNumber - b.SectionNumber
    );

    // Get the current section
    const currentSection = sortedSections[currentPage - 1];

    // Define sectionIndex here
    const sectionIndex = currentPage - 1;

    useEffect(() => {
      localStorage.setItem("formData", JSON.stringify(formData));
      // eslint-disable-next-line
    }, [formData]);

    const handleSubmit = () => {
      // Retrieve all questions from local storage and merge with formData
      const updatedSections = sortedSections.map((section) => {
        const updatedSectionQuestions = section.SectionQuestions.map(
          (question) => {
            const questionKey = `${sid}-${question.Question}`;
            const storedComment = JSON.parse(localStorage.getItem(questionKey));

            if (storedComment) {
              // Merge the answer and comment from local storage into the question from formData
              return { ...question, ...storedComment };
            }

            return question;
          }
        );
        return { ...section, SectionQuestions: updatedSectionQuestions };
      });

      // Only check if all questions have been answered if this is the last page
      if (currentPage === sortedSections.length) {
        // Find all unanswered questions
        const newUnansweredQuestions = [];
        updatedSections.forEach((section) =>
          section.SectionQuestions.forEach((question) => {
            if (question.Answer === undefined || question.Answer === "") {
              newUnansweredQuestions.push(`Question ${question.Question}`); // Modify this line
            }
          })
        );

        setUnansweredQuestions(newUnansweredQuestions);

        if (newUnansweredQuestions.length > 0) {
          console.error("Not all questions have not been answered.");

          // Generate the message
          const message = newUnansweredQuestions.join(", ");

          // Show a message box with the list of unanswered questions
          alert(
            "The following questions have not been answered:\n" + message + ""
          );
          return;
        }
      }

      // Go to the next page if it's not the last page
      if (currentPage < sortedSections.length) {
        updateCurrentPage(currentPage + 1);
      } else {
        // This is the last page, perform the submit operation
        // Update formData
        updateFormData({
          ...formData,
          Sections: updatedSections,
        });

        // Nest updated sections into the new object structure
        const nestedFormData = {
          CompanyID: CompanyID,
          record: {
            ...formData,
            manager: manager,
            latlong: lat && lon ? `${lat},${lon}` : "",
            Sections: updatedSections,
          },
          type: "Review",
        };

        postUserReview(nestedFormData)
          .then((data) => {
            console.log("Posted User Review:");
            //localStorage.clear();
            localStorage.setItem("Review2024", sid);
            setIsSuccess(true);
          })
          .catch((error) => console.error("Error posting comment:", error));
      }
    };

    return (
      <div style={{ minWidth: "99%" }} ref={elementRef}>
        <Typography variant={matches ? "h5" : "h6"} gutterBottom>
          {currentSection.SectionTitle}
        </Typography>
        <Typography
          variant={matches ? "body1" : "body2"}
          gutterBottom
          sx={{ fontStyle: "italic", fontSize: matches ? "1rem" : "0.875rem" }}
        >
          {currentSection.SectionSubTite}
        </Typography>
        {currentSection.SectionQuestions.sort(
          (a, b) => a.Question - b.Question
        ).map((questionData, questionIndex) => {
          const comment = commentsByQuestion[questionData.Question];
          //console.log("QuestionIndex", questionIndex);
          switch (questionData.QuestionType) {
            case "MultichoiceQuestion":
              return (
                <MultichoiceQuestion
                  key={questionIndex}
                  questionNumber={questionData.Question}
                  question={`${questionData.Question}. ${questionData.QuestionText}`}
                  questionSubtext={
                    questionData.QuestionSubText
                      ? questionData.QuestionSubText
                      : undefined
                  }
                  options={questionData.Options}
                  userType={comment && comment.CommentValue !== "" ? "manager" : undefined}
                  CommentUser={comment && comment.CommentValue !== "" ? CommentsData.manager : undefined}
                  CommentValue={comment && comment.CommentValue !== "" ? comment.CommentValue : undefined}
                  style={{
                    color: unansweredQuestions.some(
                      (q) =>
                        q.sectionIndex === sectionIndex &&
                        q.questionIndex === questionIndex
                    )
                      ? "red"
                      : "black",
                  }}
                />
              );
            case "WrittenAnswerQuestion":
              return (
                <WrittenAnswerQuestion
                  key={questionIndex}
                  questionNumber={questionData.Question}
                  question={`${questionData.Question}. ${questionData.QuestionText}`}
                  questionSubText={
                    questionData.QuestionSubtext
                    // ? questionData.QuestionSubText
                    // : undefined
                  }
                  userType={comment && comment.CommentValue !== "" ? "manager" : undefined}
                  CommentUser={comment && comment.CommentValue !== "" ? CommentsData.manager : undefined}
                  CommentValue={comment && comment.CommentValue !== "" ? comment.CommentValue : undefined}
                  style={{
                    color: unansweredQuestions.some(
                      (q) =>
                        q.sectionIndex === sectionIndex &&
                        q.questionIndex === questionIndex
                    )
                      ? "red"
                      : "black",
                  }}
                />
              );
            case "FileUploadQuestion":
              return (
                <FileUploadQuestion
                  key={questionIndex}
                  question={`${questionData.Question}. ${questionData.QuestionText}`}
                  userType={comment && comment.CommentValue !== "" ? "manager" : undefined}
                  CommentUser={comment && comment.CommentValue !== "" ? CommentsData.manager : undefined}
                  CommentValue={comment && comment.CommentValue !== "" ? comment.CommentValue : undefined}
                />
              );
            default:
              return null;
          }
        })}
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Button
            variant="contained"
            color="primary"
            style={{ width: "90%", marginBottom: "2rem" }}
            onClick={handleSubmit}
          >
            {currentPage < sortedSections.length ? "Next" : "Submit"}
          </Button>
        </div>
      </div>
    );
  }

  return (
    <Box
      sx={{
        overflowY: "auto",
        minWidth: "100%",
        height: "100vh",
        "&::-webkit-scrollbar": {
          width: "0.2em",
        },
        "&::-webkit-scrollbar-track": {
          WebkitBoxShadow: "inset 0 0 3px rgba(0,0,0,0.00)",
        },
        "&::-webkit-scrollbar-thumb": {
          backgroundColor: "rgba(0,0,0,.1)",
        },
        "&:hover::-webkit-scrollbar-thumb": {
          backgroundColor: "rgba(0,0,0,.4)",
        },
      }}
    >
      <div>
        {isSuccess ? (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              height: "50vh",
            }}
          >
            <CheckCircleIcon
              sx={{
                fontSize: "5rem",
                color: "green",
                display: "block",
              }}
            />
            <Typography variant="h6" sx={{ textAlign: "center" }}>
              Your review has been submitted successfully
            </Typography>
            <Typography variant="h6" sx={{ textAlign: "center" }}>
              You can now close this window
            </Typography>
          </div>
        ) : (
          <Questionnaire />
        )}
      </div>
    </Box>
  );
}

export default ReviewForm;
