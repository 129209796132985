import React from 'react';
import {
    Grid,
    Box
} from "@mui/material";
import loadinggif from '../assets/Loading_Modern1.gif';

export function Loading({ message }) {
  return (
    <Grid container style={{ position: 'fixed', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', zIndex: '999', backgroundColor: 'white', boxShadow: '0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04)', borderRadius: "25px", padding: '20px', width: '500px', maxWidth: '100%' }} alignItems="center" justify="center">
      <Grid item xs={6} display="flex" justifyContent="center" alignItems="center">
        <img src={loadinggif} style={{ maxWidth: '200px', width: '100%', height: 'auto' }} alt="Loading" />
      </Grid>
      <Grid item xs={6} display="flex" justifyContent="center" alignItems="center">
        <Box display="flex" alignItems="center" height="100%" position="absolute" left="35%" top="0%" transform="translate(-50%, -50%)" fontSize={"0.65w"} fontWeight={"Bold"}>
          {message}
        </Box>
      </Grid>
    </Grid>
  );
};

export default Loading;