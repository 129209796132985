import React, { useRef, useEffect, useState } from "react";
import Header from "../../components/Header";
import Section from "../Section/Section";
import Footer from "../../components/Footer";
import ManagerInterface from "../Manager/Manager";
import WallPaper from "../../assets/SchickValues.png";
import { useUser } from "../../providers/UserContext";
import { useAppContext } from "../../providers/AppContext";
import useMyGeolocation from "../../hooks/useGeoLocation";
import ReviewForm from "../../components/Reviews/Review";
import { v4 as uuidv4 } from "uuid";
import { fetchDirectReports } from "../../hooks/usefetchOtherUserInfo";
import {
  getReviewTemplates,
  getManagerUserComment,
  getUserReview,
} from "../../hooks/usefetchCivilxApi";
import CompanyData from "../../providers/Company.json";

const Dashboard = () => {
  const headerRef = useRef(null);
  const footerRef = useRef(null);
  const {
    sid,
    jobTitle,
    manager,
    firstName,
    lastName,
    email,
    department,
    companyName,
    managerid,
  } = useUser();
  const {
    formData,
    updateFormData,
    updateIsManager,
    ManagerMenu,
    updateCompanyID,
    updateTemplateMenu,
  } = useAppContext();
  const { lat, lon } = useMyGeolocation();
  const [directReports, setDirectReports] = useState([]);
  const [CommentsData, setCommentsData] = useState([]);
  const [noTemplate, setNoTemplate] = useState(false);
  const [ReviewCompleted, setReviewCompleted] = useState(false);

  console.log("Location : ", lat, lon);
  console.log("Manager: ", manager);

  useEffect(() => {
    const fetchCommentsAndTemplates = async () => {
      try {
        const templatesResponse = await getReviewTemplates();
        //console.log('templatesResponse:', templatesResponse)
        const templateTypes = templatesResponse.flatMap((item) =>
          item.record.Templates.map((template) => template.Type)
        );
        //console.log('templateTypes:', templateTypes);
        updateTemplateMenu(templateTypes);

        const UserReview = await getUserReview(sid);
        console.log("UserReview:", UserReview);
        if (UserReview && UserReview.length > 0) {
          console.log("user has a completed review.");
          setReviewCompleted(true);
          return;
        }

        const ManagerComments = await getManagerUserComment(managerid, sid);
        if (!ManagerComments || ManagerComments.length === 0) {
          console.error(
            "No comments found for the manager and session ID provided."
          );
          setNoTemplate(true);
          return;
        }
        let QuestionTemplate =
          ManagerComments[0].record.Comments[0].QuestionTemplate;
        //console.log(QuestionTemplate);

        //const storedComments = JSON.parse(localStorage.getItem(sid));
        let commentsToUse;
        if (ManagerComments && ManagerComments[0]?.record?.Comments) {
          // Process new comments if stored comments do not exist
          commentsToUse = ManagerComments[0].record.Comments.flatMap(
            (comment) =>
              comment.Questions.map((question) => ({
                Question: question.Question,
                CommentValue: question.CommentValue || "",
              }))
          );
          setCommentsData({
            manager: managerid,
            id: sid,
            QuestionTemplate: QuestionTemplate,
            Questions: commentsToUse,
          });
        } else {
          console.warn(
            "No matching template found for the type:",
            QuestionTemplate
          );
        }
        const templateRecord = templatesResponse.find((item) =>
          item.record.Templates.some(
            (template) => template.Type === QuestionTemplate
          )
        );
        if (templateRecord) {
          const template = templateRecord.record.Templates.find(
            (t) => t.Type === QuestionTemplate
          );
          if (template) {
            try {
              const storedFormData = JSON.parse(
                localStorage.getItem("formData")
              );
              if (
                storedFormData &&
                storedFormData.Type === template.Type &&
                JSON.stringify(storedFormData) === JSON.stringify(template)
              ) {
                updateFormData(storedFormData);
              } else {
                console.log("Manager: ", manager);
                const updatedTemplate = {
                  ...template,
                  uid: uuidv4(),
                  manager: manager,
                  date: new Date().toISOString(),
                  latlon: lat && lon ? `${lat},${lon}` : "",
                  employee: {
                    id: sid,
                    FirstName: firstName,
                    LastName: lastName,
                    Email: email,
                    JobTitle: jobTitle,
                    Division: department,
                    Company: companyName,
                  },
                  Sections: template.Sections.map((section) => ({
                    ...section,
                    SectionQuestions: section.SectionQuestions.map(
                      (question) => {
                        const matchingComment = CommentsData?.Questions?.find(
                          (comment) => comment.Question === question.Question
                        );
                        return {
                          ...question,
                          ManagerComment: matchingComment
                            ? matchingComment.CommentValue
                            : "",
                        };
                      }
                    ),
                  })),
                };
                localStorage.setItem(
                  "formData",
                  JSON.stringify(updatedTemplate)
                );
                updateFormData(updatedTemplate);
                setNoTemplate(false);
              }
            } catch (e) {
              console.error("Error handling template data:", e);
            }
          }
        } else {
          console.warn(
            "No template record found containing the type:",
            QuestionTemplate
          );
        }
        // Fetch and process template menu options and further data if needed
      } catch (error) {
        console.error("Failed to fetch comments or templates:", error);
      }
    };

    fetchCommentsAndTemplates();

    // eslint-disable-next-line
  }, [email, managerid, sid]);

  // Fetch the directReports data from Microsoft Graph API
  useEffect(() => {
    const mysid = async () => {
      try {
        if (sid) {
          fetchDirectReports(sid).then((data) => {
            const users = data.value
              .filter((user) => user["@odata.type"] === "#microsoft.graph.user")
              .sort((a, b) => {
                // If a doesn't have a jobTitle or it's null, sort it to the end
                if (!a.jobTitle || a.jobTitle === null) return 1;
                // If b doesn't have a jobTitle or it's null, sort it to the end
                if (!b.jobTitle || b.jobTitle === null) return -1;

                const titleComparison = a.jobTitle.localeCompare(b.jobTitle);
                if (titleComparison !== 0) return titleComparison; // If jobTitles are not equal, sort by jobTitle

                // If displayName is null, use an empty string
                const aDisplayName = a.displayName || "";
                const bDisplayName = b.displayName || "";

                return aDisplayName.localeCompare(bDisplayName); // If jobTitles are equal, sort by displayName
              });
            console.log("Direct Reports: ", users);

            setDirectReports(users);

            // If the user has direct reports, set isManager to true
            if (users.length > 0) {
              updateIsManager(true);
            }
          });
        }
      } catch (error) {
        console.error("Failed to fetch direct reports:", error);
      }
    };
    mysid();
    // eslint-disable-next-line
  }, [sid, updateIsManager]);

  const updateCompanyIDFromNameOrDomain = (companyName) => {
    const myCompany = async () => {
      try {
        console.log("Company Name: ", companyName);
        let foundCompany = CompanyData.find(
          (company) => company.CompanyName === companyName
        );
        if (foundCompany) {
          updateCompanyID(foundCompany.CompanyID);
        } else {
          const domain = "@" + email.split("@")[1];
          foundCompany = CompanyData.find(
            (company) => company.CompanyUPN === domain
          );
          //console.log("Found Company (Domain): ", foundCompany.CompanyID);

          if (foundCompany) {
            updateCompanyID(foundCompany.CompanyID);
          }
        }
      } catch (error) {
        console.error("Failed to update company ID:", error);
      }
    };
    myCompany();
  };

  useEffect(() => {
    updateCompanyIDFromNameOrDomain(companyName);
    // eslint-disable-next-line
  }, [companyName]);

  return (
    <div
      style={{
        position: "relative",
        display: "flex",
        flexDirection: "column",
        minHeight: "100vh",
        p: 4,
        overflow: "hidden",
        color: "white",
        backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${WallPaper})`,
        backgroundPosition: "center",
        backgroundSize: "cover",
        "@media (orientation: landscape)": {
          flexDirection: "row",
        },
      }}
    >
      <Header ref={headerRef} style={{ flexShrink: 0, zIndex: 1 }} />
      {ManagerMenu ? (
        <ManagerInterface directReports={directReports} />
      ) : (
        <Section
          style={{
            flex: 1,
            overflow: "auto",
            zIndex: 2,
            maxHeight: "calc(100vh - headerHeight - footerHeight)",
          }}
          ReviewCompleted={ReviewCompleted}
          noTemplate={noTemplate}
          children={
            <ReviewForm
              QuestionTemplate={formData}
              CommentsData={CommentsData}
            />
          }
        />
      )}
      <Footer ref={footerRef} style={{ flexShrink: 0, zIndex: 1 }} />
    </div>
  );
};

export default Dashboard;
