import React, { useEffect, useState } from "react";
import { OutlinedInput, Typography } from "@mui/material";
import { QuestionStyles, QuestionStylesSubText } from "../styles/GlobalStyles";
import { useAppContext } from "../providers/AppContext";
import useUserDetails from "../hooks/useUserDetails";
import UserComment from "./UserFeedBack";


export default function WrittenAnswerQuestion({
  questionNumber,
  question,
  questionSubtext,
  userType,
  CommentUser,
  CommentValue,
}) {
  const { formData  } = useAppContext();
  const { sid } = useUserDetails();
  const [comments, setComments] = useState([]);

  useEffect(() => {
    // Initialize new comments structure
    const initialComments = formData.Sections.flatMap((section, sectionIndex) =>
      section.SectionQuestions.map((question, questionIndex) => {
        // Attempt to retrieve existing comment from local storage
        const questionKey = `${sid}-${question.Question}`;
        const storedComment = JSON.parse(localStorage.getItem(questionKey));
  
        return storedComment || {
          Question: question.Question,
          Answer: "", // Assuming you want to store answers directly
          EmployeeComment: "",
          ManagerComment: CommentValue,
        };
      })
    );
  
    setComments({
      id: sid,
      Questions: initialComments,
    });
    // eslint-disable-next-line
  }, []); // Empty dependency array means this effect runs once on mount

const handleAnswerChange = (id, questionNumber, newAnswer) => {
  setComments((prevComments) => {
    // Create a new array with updated answers
    const updatedQuestions = prevComments.Questions.map((comment) => {
      if (comment.Question === questionNumber) {
        console.log(`Updating Question ${questionNumber} with new answer.`);
        return { ...comment, Answer: newAnswer };
      }
      return comment;
    });

    const newComments = { ...prevComments, Questions: updatedQuestions };

    // Store the updated question in localStorage under its own key
    const questionKey = `${id}-${questionNumber}`;
    const updatedQuestion = updatedQuestions.find(
      (comment) => comment.Question === questionNumber
    );
    localStorage.setItem(questionKey, JSON.stringify(updatedQuestion));

    return newComments;
  });
};
useEffect(() => {
  console.log("Component re-rendered with comments:", comments);
}, [comments]);

const currentQuestion = comments.Questions?.find(
  (comment) => comment.Question === questionNumber
);
const currentAnswer = currentQuestion ? currentQuestion.Answer : "";

  return (
    <>
      <Typography variant="body2" sx={QuestionStyles}>
        {question}
      </Typography>
      {questionSubtext && (
        <Typography variant="body2" sx={QuestionStylesSubText}>
          {questionSubtext}
        </Typography>
      )}
      <OutlinedInput
        id="written-Answer"
        value={currentAnswer}
        onChange={(e) =>
          handleAnswerChange(
            sid,
            questionNumber,
            e.target.value
          )
        }
        multiline
        rows={4}
        sx={{
          width: "99%",
          minWidth: "99%",
          mt: 2,
          mb: 2,
          backgroundColor: "#f2f2f2",
          fontSize: "0.875rem",
          borderRadius: "10px",
          "& .MuiOutlinedInput-notchedOutline": {
            borderColor: "grey",
          },
        }}
      />
      {userType && (
        <UserComment
          userType={userType}
          CommentUser={CommentUser}
          CommentValue={CommentValue}
        />
      )}
    </>
  );
}