import React from 'react';
import Attachments from './Attachments'
import { Typography } from "@mui/material";

export default function FileUploadQuestion({ question }) {
  return (
    <>
      <Typography variant="body2">{question}</Typography>
      <Attachments mode={"file"} iconcolor={"#FF0000"} dbtype={"ReviewFiles"} tag={"Review"} />
    </>
  );
}