import React, { useEffect, useState } from "react";
import {
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
  OutlinedInput,
  InputLabel,
  FormControl,
} from "@mui/material";
import UserComment from "./UserFeedBack";
import { QuestionStyles, QuestionStylesSubText } from "../styles/GlobalStyles";
import { useAppContext } from "../providers/AppContext";
import { useUser } from "../providers/UserContext";

export default function MultichoiceQuestion({
  questionNumber,
  question,
  questionSubtext,
  options,
  userType,
  CommentUser,
  CommentValue,
}) {
  const { formData } = useAppContext();
  const { sid } = useUser();
  const [comments, setComments] = useState([]);

  useEffect(() => {
    // Initialize new comments structure
    const initialComments = formData.Sections.flatMap((section, sectionIndex) =>
      section.SectionQuestions.map((question, questionIndex) => {
        // Attempt to retrieve existing comment from local storage
        const questionKey = `${sid}-${question.Question}`;
        const storedComment = JSON.parse(localStorage.getItem(questionKey));
  
        return storedComment || {
          Question: question.Question,
          Answer: "", // Assuming you want to store answers directly
          EmployeeComment: "",
          ManagerComment: CommentValue,
        };
      })
    );
  
    setComments({
      id: sid,
      Questions: initialComments,
    });
    // eslint-disable-next-line
  }, []); // Empty dependency array means this effect runs once on mount

  const handleAnswerChange = (id, questionNumber, newAnswer) => {
    setComments((prevComments) => {
      // Create a new array with updated answers
      const updatedQuestions = prevComments.Questions.map((comment) => {
        if (comment.Question === questionNumber) {
          //console.log(`Updating Question ${questionNumber} with new answer.`);
          return { ...comment, Answer: newAnswer };
        }
        return comment;
      });

      const newComments = { ...prevComments, Questions: updatedQuestions };

      // Store the updated question in localStorage under its own key
      const questionKey = `${id}-${questionNumber}`;
      const updatedQuestion = updatedQuestions.find(
        (comment) => comment.Question === questionNumber
      );
      localStorage.setItem(questionKey, JSON.stringify(updatedQuestion));

      return newComments;
    });
  };
  const handleEmployeeCommentChange = (id, questionNumber, newComment) => {
    setComments((prevComments) => {
      // Create a new array with updated answers
      const updatedQuestions = prevComments.Questions.map((comment) => {
        if (comment.Question === questionNumber) {
          //console.log(`Updating Question ${questionNumber} with new answer.`);
          return { ...comment, EmployeeComment: newComment };
        }
        return comment;
      });

      const newComments = { ...prevComments, Questions: updatedQuestions };

      // Store the updated question in localStorage under its own key
      const questionKey = `${id}-${questionNumber}`;
      const updatedQuestion = updatedQuestions.find(
        (comment) => comment.Question === questionNumber
      );
      localStorage.setItem(questionKey, JSON.stringify(updatedQuestion));

      return newComments;
    });
  };

  const currentQuestion = comments.Questions?.find(
    (comment) => comment.Question === questionNumber
  );
  const answer = currentQuestion ? currentQuestion.Answer : "";
  const employeeComment = currentQuestion
    ? currentQuestion.EmployeeComment
    : "";

  return (
    <>
      <Typography variant="body2" sx={QuestionStyles}>
        {question}
      </Typography>
      {questionSubtext && (
        <Typography variant="body2" sx={QuestionStylesSubText}>
          {questionSubtext}
        </Typography>
      )}
      <RadioGroup
        aria-label="multichoice"
        name="multichoice"
        value={answer}
        onChange={(e) =>
          handleAnswerChange(sid, questionNumber, e.target.value)
        }
      >
        {options.map((option, index) => (
          <FormControlLabel
            key={index}
            value={option.value}
            control={<Radio />}
            label={<Typography variant="body2">{option.label}</Typography>}
          />
        ))}
      </RadioGroup>
      <FormControl variant="outlined" sx={{ width: "99%", mt: 2, mb: 2 }}>
        <InputLabel htmlFor="employee-comment">Your Comment</InputLabel>
        <OutlinedInput
          id="employee-comment"
          value={employeeComment}
          onChange={(e) =>
            handleEmployeeCommentChange(sid, questionNumber, e.target.value)
          }
          label="My Comment"
          multiline
          rows={4}
          sx={{
            backgroundColor: "#f2f2f2",
            fontSize: "0.875rem",
            borderRadius: "10px",
            "& .MuiOutlinedInput-notchedOutline": {
              borderColor: "grey",
            },
          }}
        />
      </FormControl>
      {userType && (
        <UserComment
          userType={userType}
          CommentUser={CommentUser}
          CommentValue={CommentValue}
        />
      )}
    </>
  );
}
