// Header.js
import React from "react";
import { useNavigate } from "react-router-dom";
import civilxlogo from "../assets/Civilx Logo Main White.svg";
import useUserDetails from "../hooks/useUserDetails";
import SupervisorAccountIcon from "@mui/icons-material/SupervisorAccount";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import { styled } from "@mui/system";
import { Box, Grid, useMediaQuery, Tooltip } from "@mui/material";
import Badge from "@mui/material/Badge";
import Avatar from "@mui/material/Avatar";
import { useAppContext } from "../providers/AppContext";


const Header = React.forwardRef((props, ref) => {
  const { profilePicture } = useUserDetails();
  const matches = useMediaQuery("(min-width:600px)");
  const { ManagerMenu, updateManagerMenu, isManager } = useAppContext();
  const navigate = useNavigate();

  const handleManagerOpen = () => {
    updateManagerMenu(!ManagerMenu);
  };

  const handleLocalStorage = () => {
    const userConfirmed = window.confirm('This will reset your form including all your answers and comments. Are you sure you want to proceed?');

    if (userConfirmed) {
    localStorage.clear();
    navigate("/")
    window.location.reload();
    }
  };

  const HeaderContainer = styled(Box)({
    display: "flex",
    flexDirection: "row",
    // alignItems: 'center',
    position: "relative",
    padding: 1,
  });

  const MainContainer = styled(Grid)({
    display: "grid",
    gridTemplateColumns: "auto 1fr",
    gridColumnGap: "1rem",
  });

  const ProfileContainer = styled("div")({
    display: "flex",
    alignItems: "left",
    marginLeft: 10,
    marginTop: 10,
  });

  const ProfileImage = styled(Avatar)(({ theme }) => ({
    boxShadow: "0 0 10px rgba(0, 0, 0, 0.5)",
    marginLeft: 10,
    borderRadius: "50%",
    width: 40,
    height: 40,
    objectFit: "cover",
  }));

  const StyledBadge = styled(Badge)(({ theme }) => ({
    "& .MuiBadge-badge": {
      backgroundColor: "#44b700",
      color: "#44b700",
      boxShadow: `0 0 0 2px black`,
      "&::after": {
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        borderRadius: "50%",
        animation: "ripple 1.2s infinite ease-in-out",
        border: "1px solid currentColor",
        content: '""',
      },
    },
    "@keyframes ripple": {
      "0%": {
        transform: "scale(.8)",
        opacity: 1,
      },
      "100%": {
        transform: "scale(2.4)",
        opacity: 0,
      },
    },
  }));

  const Greeting = styled("span")({
    color: "white",
    marginLeft: 10,
    marginTop: 10,
    fontWeight: "bold",
    fontSize: matches ? "0.85vw" : "3vw",
    fontFamily: "Roboto",
    backgroundColor: "transparent",
  });

  return (
    <div ref={ref}>
      <HeaderContainer>
        <Box sx={{ position: "absolute", top: 5, left: 0 }}>
          {/* <MenuIcon sx={{ color: "white" }} fontSize="medium" /> */}
          <MainContainer>
            <ProfileContainer>
              <StyledBadge
                overlap="circular"
                anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                variant="dot"
              >
                <ProfileImage src={profilePicture} alt="Profile" />
              </StyledBadge>
              <Greeting>
              </Greeting>
            </ProfileContainer>
          </MainContainer>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            width: "100%",
            marginBottom: 3,
          }}
        >
          <img
            src={civilxlogo}
            alt="CivilX"
            style={{ height: 35, marginTop: 15 }}
          />
        </Box>

        <Box sx={{ position: "absolute", top: 15, right: 25 }}>
        {isManager && (
        <Tooltip title="Manager Comments">
          <Badge color="primary" badgeContent={0}>
     
              <SupervisorAccountIcon
                onClick={handleManagerOpen}
                sx={{ color: "white", marginRight: 1 }}
              />

          </Badge>
          </Tooltip>
                      )}
          <Tooltip title="Reset all">
            <Badge color="primary" badgeContent={0}>
              <RestartAltIcon sx={{ color: "white" }} onClick={handleLocalStorage} />
            </Badge>
          </Tooltip>
        </Box>
      </HeaderContainer>
    </div>
  );
});

export default Header;
