import { createContext, useContext, useState, useEffect } from 'react';

const UserContext = createContext(undefined);

export const UserProvider = ({ children }) => {
    const [profilePicture, setProfilePicture] = useState(null);
    const [firstName, setFirstName] = useState(null);
    const [lastName, setLastName] = useState(null);
    const [email, setEmail] = useState(null);
    const [jobTitle, setJobTitle] = useState(null);
    const [mobilePhone, setMobilePhone] = useState(null);
    const [department, setDepartment] = useState(null);
    const [officeLocation, setOfficeLocation] = useState(null);
    const [city, setCity] = useState(null);
    const [companyName, setCompanyName] = useState(null);
    const [userRole, setUserRole] = useState(null);
    const [manager, setManager] = useState(null);
    const [managerPicture, setManagerPicture] = useState(null);
    const [managerFirstName, setManagerFirstName] = useState(null);
    const [managerLastName, setManagerLastName] = useState(null);
    const [sid, setsid] = useState(null);
    const [managerid, setManagerid] = useState(null);
  

    useEffect(() => {
        // On component mount, try to fetch the profile picture and other attributes from localStorage
        const cachedPicture = localStorage.getItem('userProfilePicture');
        const cachedFirstName = localStorage.getItem('userFirstName');
        const cachedLastName = localStorage.getItem('userLastName');
        const cachedEmail = localStorage.getItem('userEmail');
        const cachedJobTitle = localStorage.getItem('userJobTitle');
        const cachedMobilePhone = localStorage.getItem('userMobilePhone');
        const cachedDepartment = localStorage.getItem('userDepartment');
        const cachedOfficeLocation = localStorage.getItem('userOfficeLocation');
        const cachedCity = localStorage.getItem('userCity');
        const cachedCompanyName = localStorage.getItem('userCompanyName');
        const cachedUserRole = localStorage.getItem('userUserRole');
        const cachedManager = localStorage.getItem('userManager');
        const cachedManagerPicture = localStorage.getItem('ManagerPicture');
        const cachedManagerFirstName = localStorage.getItem('ManagerFirstName');
        const cachedManagerLastName = localStorage.getItem('ManagerLastName');
        const cachedsid = localStorage.getItem('userId');
        const cachedManagerid = localStorage.getItem('managerid');

        if (cachedPicture) {
            setProfilePicture(cachedPicture);
        }
        if (cachedFirstName) {
            setFirstName(cachedFirstName);
        }
        if (cachedLastName) {
            setLastName(cachedLastName);
        }
        if (cachedEmail) {
            setEmail(cachedEmail);
        }
        if (cachedJobTitle) {
            setJobTitle(cachedJobTitle);
        }
        if (cachedMobilePhone) {
            setMobilePhone(cachedMobilePhone);
        }
        if (cachedDepartment) {
            setDepartment(cachedDepartment);
        }
        if (cachedOfficeLocation) {
            setOfficeLocation(cachedOfficeLocation);
        }
        if (cachedCity) {
            setCity(cachedCity);
        }
        if (cachedCompanyName) {
            setCompanyName(cachedCompanyName);
        }
        if (cachedUserRole) {
            setUserRole(cachedUserRole);
        }
        if (cachedManager) {
            setManager(cachedManager);
        }
        if (cachedManagerPicture) {
            setManagerPicture(cachedManagerPicture);
        }
        if (cachedManagerFirstName) {
            setManagerFirstName(cachedManagerFirstName);
        }
        if (cachedManagerLastName) {
            setManagerLastName(cachedManagerLastName);
        }
        if (cachedsid) {
            setsid(cachedsid);
        }
        if (cachedManagerid) {
            setManagerid(cachedManagerid);
        }
    }, []);

    return (
        <UserContext.Provider 
            value={{ 
                profilePicture, 
                setProfilePicture, 
                firstName, 
                setFirstName, 
                lastName, 
                setLastName,
                email,
                setEmail,
                jobTitle,
                setJobTitle,
                mobilePhone,
                setMobilePhone,
                department,
                setDepartment,
                officeLocation,
                setOfficeLocation,
                city,
                setCity,
                companyName,
                setCompanyName,
                userRole,
                setUserRole,
                manager,
                setManager,
                managerPicture,
                setManagerPicture,
                managerFirstName,
                setManagerFirstName,
                managerLastName,
                setManagerLastName,
                sid,
                setsid,
                managerid,
                setManagerid,
            }}
        >
            {children}
        </UserContext.Provider>
    );
};

export const useUser = () => {
    const context = useContext(UserContext);
    if (context === undefined) {
        throw new Error('useUser must be used within a UserProvider');
    }
    return context;
};