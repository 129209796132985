

import defaultImage from '../assets/CivilMan.png';
const { REACT_APP_API_ENDPOINT, REACT_APP_API_KEY } = process.env;


// Function to get user information
export async function getUserInfo(displayName) {
    const url = `${REACT_APP_API_ENDPOINT}/users?displayName=${encodeURIComponent(displayName)}&code=${REACT_APP_API_KEY}&clientId=CIVILX`;

    try {
        const response = await fetch(url);
        const data = await response.json();
        return data;
    } catch (error) {
        return console.error('Error:', error);
    }
}

export async function getUserPicture(id) {
    const url = `${REACT_APP_API_ENDPOINT}/userPicture/${id}?code=${REACT_APP_API_KEY}&clientId=CIVILX`;

    try {
        const response = await fetch(url);

        // If the response status is 404, return the default image
        if (response.status === 404) {
            console.error('No image found');
            return defaultImage;
        }

        const blob = await response.blob();
        return URL.createObjectURL(blob);
    } catch (error) {
        console.error('Error:', error);
        return defaultImage;
    }
}

export async function fetchDirectReports(id) {
    const url = `${REACT_APP_API_ENDPOINT}/directReports/${id}?code=${REACT_APP_API_KEY}&clientId=CIVILX`;

    try {
        const response = await fetch(url);
        const data = await response.json();
        return data;
    } catch (error) {
        return console.error('Error:', error);
    }
}

export async function fetchUserManager(id) {
    const url = `${REACT_APP_API_ENDPOINT}/managers/${id}?code=${REACT_APP_API_KEY}&&clientId=CIVILX`;

    try {
        const response = await fetch(url);
        const data = await response.json();
        return data;
    } catch (error) {
        return console.error('Error:', error);
    }
}


