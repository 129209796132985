import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import DashBoard from '../pages/Dashboard/Dashboard';

const AppRoutes = () => {
  return (
    <Router>
      <Routes>
        {/* <Route path="/" element={<Landing />} /> */}
        <Route path="/" element={<DashBoard />} />
      </Routes>
    </Router>
  );
};

export default AppRoutes;
